import React from "react";
import { useEffect } from "react"; 
import $ from 'jquery';


function Value(){
            useEffect(() => {
                    
                $( ".Description" ).hide();
				$( ".Description" ).slideDown(800);

        },[])
    return <>
            <p className="Description"> <a href="http://smartinspection.ca">
                <img src={require('../images/inspector-logo.png')} alt="property inspection" width="70" height=""/></a><br /><b> Values</b><br />
                <b>· Property Inspection</b><br/>
                <b>· Design Management</b><br/>
                <b>· Thermal Imaging</b><br/>
                <b>· Feasibility Study</b><br/>
                <b>· Project Monitoring</b><br/>
                <b>· Development Consultancy</b><br/>
            </p>
            </>
   ;

}

export default Value;