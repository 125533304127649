import React from "react";
import $ from 'jquery';
import { useEffect } from "react";
function OurStory(){
    useEffect(() => {
            
            $( ".Description" ).hide();
            $( ".Description" ).slideDown(800);

    },[])

    return <>
            <p className="Description"> <a href="http://smartinspection.ca"><img src={require('../images/inspector-logo.png')} alt="property inspection" width="70" height=""/></a><br /><b>Work with us</b><br/>
                        Welcome<br/>
                        We believe that experience counts! It is the unique combination of our
                            internationally qualified and experienced consultancy team, the many
                            years of experience gained working in Canada and the Middle East that 
                            enables us to offer you end-to-end real estate solutions based on our
                        scope of work.<br/><br/><br/><br/><br/>

            </p>
            </>
   ;

}

export default OurStory;