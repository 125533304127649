import React, { Component,useState } from "react";
import ChildComponent from './NavChild';
import Menu from "./Menu";
import { useReducer } from "react";
import reducer from "../reducers/reducers";
import initialState from "../reducers/initials";
import Home from "./Home";
import Value from "./Value";
import OurStory from "./OurStory";
import Particles from "react-tsparticles"; 
import { loadFull } from "tsparticles"; 
function App(){
  const particlesInit = async (main) => { 
    console.log(main); 
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets 
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready 
    // starting from v2 you can add only the features you need reducing the bundle size 
    await loadFull(main); 
  }; 
  const particlesLoaded = (container) => { 
    console.log(container); 
  }; 
  const [texts, dispatch] = useReducer(reducer, initialState);
  return(
  <div>
          <nav className="navbar container">
         
            <div className="menu" id="menu">
              <ul className="menu-list">
                    {Menu.Menu.map(({name,className}) => (

                            
                          <>
                          <li className="user text-center text-white" onClick={() => dispatch({ type: name })}> 
                          <ChildComponent person={name} className={className} />
                          </li>
                          </>
                    ))}
               </ul>

               

 
            </div>
           
          </nav>
          <div className="text-center" id="lcweb">      
              
              {texts.content}
             </div>
             <Particles 
        id="tsparticles" 
        init={particlesInit} 
        loaded={particlesLoaded} 
        options={{ 
          
            detectRetina: false,
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onHover: {
                  enable: true,
                  mode: "bubble"
                },
                resize: true
              },
              modes: {
                bubble: {
                  distance: 40,
                  duration: 2,
                  opacity: 8,
                  size: 10,
                  speed: 3
                }
              }
            },
            particles: {
              color: {
                value: "#fff",
                animation: {
                  enable: true,
                  speed: 10,
                  sync: true
                }
              },
              lineLinked: {
                blink: false,
                color: "random",
                consent: false,
                distance: 60,
                enable: true,
                opacity: 0.3,
                width: 0.8
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200
                  }
                },
                bounce: true,
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: true,
                speed: 1.5,
                straight: false
              },
              number: {
                density: {
                  enable: false,
                  area: 200
                },
                limit: 0,
                value: 80
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.05,
                  speed: 2,
                  sync: false
                },
                random: false,
                value: 1
              },
              shape: {
                type: "circle"
              },
              size: {
                animation: {
                  enable: false,
                  minimumValue: 1.1,
                  speed: 40,
                  sync: false
                },
                random: true,
                value: 1
              }
            },
            polygon: {
              draw: {
                enable: true,
                lineColor: "rgba(255,255,255,0.2)",
                lineWidth: 0.3
              },
              move: {
                radius: 10
              },
              inlineArrangement: "equidistant",
              scale: 0.5,
              type: "inline",
              url: "https://particles.js.org/images/smalldeer.svg"
            },
        }} 
      /> 
</div>
)}
export default App;