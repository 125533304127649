import React from "react";
import { useEffect } from "react"; 
import $ from 'jquery';
function ReachOut(){
			useEffect(() => {
					
				$( ".Description" ).hide();
				$( ".Description" ).slideDown(800);
				$( ".locate" ).hide();
				$( ".locate" ).show(1500);
		},[])
    return <>
            
			
			<p className="Description"><strong>Questions &amp; Estimates</strong><br/>
			We’d love to hear from you! Please reach out at your convenience so we can discuss your project.<br/>
			<i class="fa-solid fa-location-dot"></i> Smartinspection.ca<br/>300 2240 Chippendale Road, West Vancouver, British Columbia V7S 3J5, Canada<br/>
			<i class="fa-solid fa-envelope"></i> <a href="mailto:biz@smartinspection.ca">biz@smartinspection.ca</a><br/>
			<i class="fa-solid fa-phone"></i> +1(604)6761414<br/><strong><i class="fa-solid fa-clock"></i> Hours :</strong><br/>
			Mon : 09:30 a.m. – 06:30 p.m.<br/>Tue : 09:30 a.m. – 06:30 p.m.<br/>
			Wed : 09:30 a.m. – 06:30 p.m.<br/>Thu : 09:30 a.m. – 06:30 p.m.<br/>
			Fri : 09:30 a.m. – 05:30 p.m.<br/>Sat : By Appointment<br/>Sun: Closed<br/>
            </p>
			<iframe className="locate" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=smartinspection.ca&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="smartinspection.ca" aria-label="smartinspection.ca"></iframe>
            </>
   ;

}

export default ReachOut;