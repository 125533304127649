import React from "react";
import $ from 'jquery';
import NetworkData from "./NetworkData";
import { useReducer } from "react";
import NetWorkDataLayout from "./NetWorkDataLayout";

function NetWorkDots(){

        return(
    
                 <div className="worldmap__markers worldmap__markers--dots">
                          {NetworkData.NetworkData.map(({id,top,left,country,url,image}) => (
                          <>
                                    <div id={id} className="worldmap__marker" style={{"left":left,"top":top}}>
                                    <div className="worldmap__pin" style={{"color":"orange"}}></div>
                                    </div>
                                    <NetWorkDataLayout country={country} url={url} image={image} id={id} />
                        </>
                            ))}                       
                 </div>        
        )
}


export default NetWorkDots;