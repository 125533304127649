export const Menu  = [
    {
      id: '1',
      name: 'Home',
      className:'menu-icon fa-solid fa-home',
    },
    {
      id: '2',
      name: 'Value',
      className:'menu-icon fa-solid fa-circle-dollar-to-slot',

    },
    {
      id: '3',
      name: 'Our Story',
      className:'menu-icon fa-solid fa-file-waveform',

    },
    {
      id: '4',
      name: 'Network',
      className:'menu-icon fa-solid fa-globe',

    },
    {
      id: '5',
      name: 'Reach Out',
      className:'menu-icon fa-solid fa-location-dot',

    },
  ]
   export default {
      Menu,
  }