import React, { Component,useState } from "react";
import $ from 'jquery';
import NetWorkDots from "./NetWorkDots";
import NetworkLayout from "./NetWorkLayout";
import NetworkData from "./NetworkData";
import { useEffect } from "react";

function NetWorkDataLayout(props){

            useEffect(() => {
                $( `#${props.id}-info` ).hide();
                $( `#${props.id}` ).click(function() {
                    $(".worldmap__office").hide();
                    $( ".worldmap__office-address" ).hide();
                    //$( ".worldmap__office-image" ).hide();

                    $( `#${props.id}-info` ).fadeIn(500,function(){

                        $( ".worldmap__office-address" ).slideDown(1000);

                    });
                    $( ".worldmap__office-image" ).slideDown(3200);

                    
                });
                $( ".worldmap__office-hide").click(function() {
                    $( `#${props.id}-info` ).slideUp(400);
                    
                });
                $( `#${props.id}-info`).click(function() {
                    $( `#${props.id}-info` ).slideUp(400);
                    
                });
                
            
                    }, []);

      let dynamicfilename =props.image;
      let infoID =props.id;

    return <>

                <div style={{"top": "85px;"}} id={props.id+"-info"} className="worldmap__office">

                <img style={{"background":"black"}} className="worldmap__office-image" src={require(`../../../public/images/${dynamicfilename}.jpg`)} alt=""/>
                <div className="worldmap__office-address">
                    <span itemprop="name">
                    <a style={{"color":"white"}} href={props.url}>{props.url}</a>
                    </span><br/>

                    <span itemprop="addressLocality">{props.country}</span><br/>
                </div>
                <span style={{"color":"#fff","cursor":"pointer"}} className="worldmap__office-hide"><i class="fa fa-window-close" aria-hidden="true"></i></span>
                </div>
                                       

            </>
   ;

}

export default NetWorkDataLayout;