
import React, { Component }  from 'react';
import Home from "../components/Home";
import OurStory from "../components/OurStory";
import Value from "../components/Value";
import ReachOut from '../components/ReachOut';
import Network from "../components/NetworkFolder/Network";
  
    const reducer = (state, action) => {
      switch (action.type) {
        case "Home":
          return {
            ...state,
            content: <Home />,
            
          };
        case "Network":
          return {
            ...state,
            content: <Network />,
    
          };
          case "Value":
          return {
            ...state,
            content: <Value />,
    
          };
          case "Our Story":
            return {
              ...state,
              content: <OurStory />,
      
            };
            case "Reach Out":
              return {
                ...state,
                content: <ReachOut />,
        
              };
      }
    }


        
    
export default reducer;