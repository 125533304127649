import React from "react";
import $ from 'jquery';
import NetWorkDots from "./NetWorkDots";
import NetworkLayout from "./NetWorkLayout";
import NetWorkDataLayout from "./NetWorkDataLayout";
function Network(){
   
    return <>

                    <NetworkLayout />
                                       

            </>
   ;

}

export default Network;