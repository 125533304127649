export const NetworkData  = [
    {
      id: 'toronto',
      url: 'https://www.panpartners.org',
      country:'Toronto , USA',
      image:'pan',
      top:"31%",
      left:"19%",
    },
    {
        id: 'montreal',
        url: 'https://www.wsp.com',
        country:'Montreal',
        image:'montreal',
        top:"25%",
        left:"19%",
      },
      {
        id: 'vancouver',
        url: 'www.ikor.ca , www.fyarch.ca ,www.casabellabuilder.com',
        country:'vancouver',
        image:'vancouver',
        top:"25%",
        left:"11%",
      },
      {
        id: 'sweden',
        url: 'https://www.bonosolutions.com/',
        country:'Sweden , Singapore',
        image:'sweden',
        top:"15%",
        left:"49%",
      },
      {
        id: 'singapore',
        url: 'https://www.bonosolutions.com/',
        country:'singapore',
        image:'singapore',
        top:"56%",
        left:"79%",
      },
      {
        id: 'France',
        url: 'https://avestagroup.eu',
        country:'France,UAE',
        image:'France',
        top:"25%",
        left:"46%",
      },
      {
        id: 'UAE',
        url: 'https://avestagroup.eu',
        country:'France,UAE',
        image:'UAE',
        top:"42%",
        left:"61%",
      },
      {
        id: 'USA',
        url: 'https://www.panpartners.org',
        country:'Toronto , USA',
        image:'pan',
        top:"33%",
        left:"14%",
      },
      {
        id: 'OMAN',
        url: 'https://cci-oman.com/',
        country:'OMAN',
        image:'OMAN',
        top:"42%",
        left:"63%",
      },
      {
        id: 'kuwait',
        url: 'https://www.promontorio.net/',
        country:'portugal,kuwait',
        image:'kuwait',
        top:"36%",
        left:"60%",
      },
      {
        id: 'portugal',
        url: 'https://www.promontorio.net/',
        country:'portugal,kuwait',
        image:'kuwait',
        top:"36%",
        left:"60%",
      },
      {
        id: 'iran',
        url: 'https://www.iranmall.com/',
        country:'IRAN',
        image:'iran',
        top:"34%",
        left:"62%",
      },
  ]
   export default {
    NetworkData,
  }