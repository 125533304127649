import React from "react";


const NavChild =(props)=>(

   



    <>
        <i className={props.className}></i>    
        <span className="menu-name">{props.person} </span>
    </>
    
)

export default NavChild